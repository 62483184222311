import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const calculateTimeLeft = () => {
    const weddingDate = new Date('2025-10-11T00:00:00-05:00');
    const difference = +weddingDate - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <div className="App">
      <div className="background">
        <div className="content">
          <h1>Save the Date</h1>
          <h2>October 11th, 2025</h2>
          <div className="countdown">
            {Object.keys(timeLeft).map((interval) => (
              <div key={interval}>
                <span>{timeLeft[interval]}</span> {interval}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;